import React from "react"
import SEO from "../../components/seo"
import Layout from "../../layouts/layout"
import Banner from "../../components/banner"
import styled from "styled-components"
import style from "../../assets/global-style"

const Container = styled.div`
  margin-bottom: 14rem;

  .section1 {
    margin: 0 auto;
    max-width: 1200px;
    height: 24rem;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;

    img {
      width: 4rem;
    }

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }

    .title {
      font-size: 2.4rem;
      letter-spacing: 5px;
      color: ${style["theme-color"]};
    }

    .desc {
      border: 1px solid #ccc;
      margin: 10px 20px;
      overflow-y: scroll;
      height: 60%;
      padding: 20px 28px;
      letter-spacing: 1.6px;
      text-indent: 2em;

      &:hover {
        ::-webkit-scrollbar-thumb {
          background-color: ${style["theme-color"]};
        }
      }

      &::-webkit-scrollbar {
        width: 16px;
        background-color: #dfe0e2;
        padding: 10px 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        transition: background-color 2s;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: ${style["theme-color"]};
      }
    }
  }

  .section2 {
    margin: 0 auto;
    margin-top: 42px;
    max-width: 1200px;

    .title {
      text-align: center;
      font-size: 2.4rem;
      letter-spacing: 5px;
      color: ${style["theme-color"]};
      margin: 10px;
    }

    img {
      width: 100%;
      height: 100%;
      padding: 10px;
    }
  }

  .section3 {
    width: 100%;
    height: 30rem;
    margin-top: 40px;
    background-color: #dfe0e2;
    padding: 1rem;
    padding-top: 30px;

    ${style.flexBox("column", "center")}

    .title {
      color: ${style["theme-color"]};
      text-align: center;
      font-size: 2.4rem;
    }

    .desc {
      margin-top: 0;
      padding: 1rem;
      p {
        margin-bottom: 0;
        font-size: 28px;
      }

      p > span {
        font-size: 3.2rem;
        color: ${style["theme-color"]};
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .section4 {
    margin-top: 4rem;

    ${style.flexBox("column")}
    .title {
      color: ${style["theme-color"]};
      text-align: center;
      font-size: 2.4rem;
      margin-bottom: 20px;
    }
    img {
      display: inline-block;
      max-width: 1200px;
      height: 60rem;
    }
  }

  .section5 {
    position: relative;
    margin-top: 4rem;

    .title {
      position: absolute;
      top: 4rem;
      left: 50%;
      transform: translateX(-50%);
      color: ${style["theme-color"]};
      text-align: center;
      font-size: 2.4rem;
      margin-bottom: 20px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 12rem;

    .section1 {
      .title {
        font-size: 24px;
      }
    }

    .section2 {
      .title {
        font-size: 24px;
      }
    }

    .section3 {
      .title {
        font-size: 24px;
      }

      .desc {
        p {
          font-size: 18px;
          span {
            font-size: 29px;
          }
        }
      }
    }

    .section4 {
      .title {
        font-size: 24px;
      }

      img {
        height: 17rem;
      }
    }

    .section5 {
      .title {
        font-size: 16px;
        top: 5px;
      }
    }
  }
`

const Page = () => {
  return (
    <Layout>
      <SEO title="发展历程" />
      <Banner img="http://cdn.jerryshi.com/gtkj/20200103152146.jpg" />
      <Container>
        <div className="section1">
          <img
            src="http://cdn.jerryshi.com/gtkj/20200106154057.png"
            alt="logo"
          />
          <span className="title">云南城建物业集团有限公司</span>
          <div className="desc">
            <p>
              云南城建物业集团有限公司（以下简称：城建物业），成立于1997年8月，是持有中华人民共和国住建部物业服务一级资质的独立法人实体公司，服务业态横跨住宅、商业综合体、影院、写字楼、学校、酒店、公园等，目前为云南省18个项目提供全权物业委托服务，为10个项目提供物业咨询顾问服务，管理服务、顾问咨询总面积985万平米，户数达到7.9万户，覆盖住户人数27.6万人，经营范围涵盖物业服务、酒店运营、智能科技、绿化工程、房屋中介、医疗健康、教育培训、装饰装修、会务接待、社区旅游、家政护理、金融理财等方面。
            </p>
            <p>
              在多年的物业服务工作中，积累并形成了符合物业服务行业特性、独立而完善的管理体系，所服务的物业不断增值，获得了广大住户和政府主管部门的肯定与好评，管理的物业先后获得省、市、区级“物业管理示范小区”、“安全文明小区”及“园林小区”等称号，现为物业服务行业副会长单位、昆明市医养协会副会长单位、昆明市西山区工商联执委及中国指数研究院中国物业服务百强及品牌评比邀请单位，昆明市工商行政管理局
              “守合同、重信用”单位及AAA信用等级评选单位，在由云南省住建厅主办，生活新报、省精神文明办、市物业管理协会联合举办的寻找昆明“最美小区”评选中，多次获得贴心物管奖、最具人文奖、幸福和谐奖等奖项。
            </p>
            <p>
              在互联网新形式下，城建物业集团服务升级，打造互联网+智慧社区运营模式，实现住户的全方位线上线下无缝服务。在未来，城建物业将以优质服务为基础，多元化发展，打造本土最大的社区服务运营商。
            </p>
          </div>
        </div>
        <div className="section2">
          <div className="title">荣誉</div>
          <img src="http://cdn.jerryshi.com/gtkj/20200106151052.jpg" alt="r" />
        </div>
        <div className="section3">
          <p className="title">公司规模</p>
          <div className="desc">
            <p>
              业主人数<span> 27 </span>万人
            </p>
            <p>
              管理户数<span> 7.9 </span>万户 分公司 <span> 7 </span>个
              全资控股公司 <span>14</span> 个 员工人数 <span>2167</span> 人
            </p>
            <p>
              总管理面积 <span>985</span> 万平方米
            </p>
          </div>
        </div>
        <div className="section4">
          <div className="title">服务项目</div>
          <img
            src="http://cdn.jerryshi.com/gtkj/20200106174026.svg"
            alt="service"
          />
        </div>
        <div className="section5">
          <div className="title">智慧社区云服务平台</div>
          <img src="http://cdn.jerryshi.com/gtkj/20200106174339.jpg" alt="l1" />
          <img src="http://cdn.jerryshi.com/gtkj/20200106174352.jpg" alt="l1" />
        </div>
      </Container>
    </Layout>
  )
}

export default Page
